
@media (max-width: 600px) {
  .box-container {
    padding: 0px 10px 30px 10px !important;
  }  
  .ver-minha-pagina {
    padding: 10px 0px 30px 0px;
    text-align: center;
  }  
  .modal {
    top: 0px;
    padding: 5px;
    margin-bottom: 55px;
    z-index: 99999999999999 !important;
  }  
  .no-padding-mobile {
    padding: 0px;
  }
  .member {
    display: none;
  }
  .logo-mobile-member {
    display: block;
  }  
  .header-box {
    padding: 0px;
  }  
  .header-logo img {
    width: 270px;
  }
  .header-box nav {
    padding: 0px;
    margin: 0px;
    margin-top: 4px;
    width: 100%;
  }
  .header-box ul {
    padding: 0px;
    margin: 0px;
  }
  .header-box ul li {
    position: relative;
    float: left;
    line-height: 15px;
    border-right: 1px solid #f3f2ef;
    text-align: center;
    color: #022a85;
    font-weight: 300;
    padding: 0px;
  }  
  .header-box ul li a {
    text-decoration: none;
    color: #022a85;
    display:block;
    padding: 0px 0px 7px 0px;
  }  
  .header-box ul li i {
    font-size: 13px;
  }
  .header-box ul li span {
   width: 100%;
   padding: 0px;
   margin: 0px;
   font-size: 9px;
  }  
  .header-box li ul {
    top: 39px; 
  } 
  .header-box li ul li a {
    width: 100%;
    padding: 6px 6px;
  }
  .header-box li ul li {
    width: 146px;
    font-size: 9px;
  }   
  .header-avatar {
    padding: 5px 10px;
  }
  .header-avatar img {
    width: 20px;
  }
  .LayoutBannerHeaderAdmin {
    margin-top: 40px;
  }  
  .logo-mobile-member {
    width: 250px;
    padding: 6px 20px 6px 10px;
  }  
  .LayoutBannerHeader {
    padding: 5px;
  } 
  .content-box {
    padding-bottom: 25px !important;
  }
  .content-box .boxes {
    padding: 15px !important;
  }
  .carousel-indicators {
    bottom: -22px;
  }
}