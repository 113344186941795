@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0px;
  padding: 0px;
  background-color: #f3f2ef;
  * {
    outline: none;
  }
}
.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: 700;
}
.box-container {
  padding: 0px 3rem !important;
}
.ver-minha-pagina {
  padding: 18px 0px 0px 0px;
  text-align: center;
}
.logo-mobile-member {
  display: none;
}
.background-red {
  background-color: red;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.no-margin {
  margin: 0px;
}
.no-padding {
  padding: 0px;
}
.p-20 {
  padding: 20px !important;
}
.mt-100 {
  margin-top: 100px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-50 {
  margin-bottom: 50px;
}
.header-box {
  background-color: #fff;
  padding: 0px;
  margin: 0px 0px 20px 0px;
  position: relative;
  width: 100%;
  min-height: 1px;
  border-bottom: 1px solid rgba(0,0,0,0.08);
  z-index: 99999;
}
.header-box nav {
  float: right;
}
.header-box ul {
  list-style:none;
}
.header-box ul li {
  position: relative;
  float:left;
  line-height: 15px;
  border-right: 1px solid #f3f2ef;

  text-align: center;
  color: #022a85;
  font-weight: 300;
}
.header-box ul li:last-child {
  border-right: 0px;
}
.header-box ul li:hover {
  background-image: linear-gradient(to right top, #e9bf15, #e5b212, #e0a512, #db9812, #d58c14);
  color: #fff;
}
.header-box ul li a {
  text-decoration: none;
  color: #022a85;
  display:block;
  padding: 5px 10px;
}
.header-box ul li a:hover,
.header-box ul li a:active {
  color: #fff;
}
.header-box ul li .active{
  border-bottom: 2px solid #000;
}
.header-box ul li i {
  padding: 0px;
  margin: 0px;
  font-size: 22px;
  width: 100%;
  text-align: center;
 }
.header-box ul li span {
 width: 100%;
 padding: 0px;
 margin: 0px;
 font-size: 12px;
}
.header-box li ul {
  position:absolute; 
  top: 49px; 
  right: 0px; 
  background-color:#fff; 
  display:none; 
  padding: 0px;
}
.header-box li:hover ul { 
  display:block; 
}
.header-box li ul li {
  display:block; 
  width: 216px;
  color: #022a85;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border-top: 1px solid rgba(0,0,0,0.08);
  border-left: 1px solid rgba(0,0,0,0.08);
  border-right: 1px solid rgba(0,0,0,0.08);
}
.header-box li ul li a {
  width: 100%;
  padding: 10px 10px;
}
.header-search{
  background-color: transparent;
  margin: 0px;
  margin-top: 6px;  
}
.header-logo {
  text-align: center;
  background-color: transparent;
  padding: 5px 0px 8px 0px;
}
.header-logo img {
  width: 280px;
}
.header-avatar {
  padding: 5px 10px;
}
.header-avatar img {
  width: 40px;
  border-radius: 50%;
}
.boxes {
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
  border-top: 1px solid #e0dfdc;
  border-bottom: 1px solid #e0dfdc;
  border-left: 1px solid rgba(0,0,0,0.08);
  border-right: 1px solid rgba(0,0,0,0.08);
  margin-bottom: 20px;
  //box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.08);
}
.boxes ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.boxes ul li {
  padding: 5px;
  line-height: 15px;
  color: #022a85;
}
.boxes ul li:last-child {
  border: 0px;
}

.boxes ul li:hover {
  color: #fff;
}
.boxes ul li a {
  text-decoration: none;
  color: #000;
}
.boxes ul li a:hover {
  color: #022a85;
}

.boxes ul li ul {
  padding: 0px;
  margin: 10px 0px 0px 0px;
}
.boxes ul li ul li {
  font-weight: 300;
  padding: 5px;
  font-size: 13px;
  line-height: 13px;
  color: #022a85;
  border-bottom: 1px solid rgba(0,0,0,0.08);
}
.boxes ul li ul li:last-child {
  border: 0px;
}
.boxes .principal {
  font-weight: 400;
  color: #eac115;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
}

.LayoutBannerHeader {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  border-top: 1px solid #e0dfdc;
  border-bottom: 1px solid #e0dfdc;
  border-left: 1px solid rgba(0,0,0,0.08);
  border-right: 1px solid rgba(0,0,0,0.08);
}
.LayoutBannerHeader img {
  width: 100%;
  border-radius: 10px;
}
.LayoutBannerHeader .LayoutBannerHeaderCarousel {
  width: 100%;
  border-radius: 10px;
}
.LayoutBannerHeader .carousel-control-next-icon {
  color: #eac115 !important;
}
.LayoutBannerHeader .carousel-indicators .active {
  background-color: #eac115 !important;
}

.titulo-hr{
  background: url('../images/hr.jpg') repeat-x center center;
  text-align: right;
  margin: 0px 0px 15px 10px;
  
}
.titulo-hr span{
  background-color: #f3f2ee !important;
  padding: 0px 30px 0px 40px !important;
  font-size: 13px !important;
  color: #0e0d30 !important;
}

.btn-agendar-reuniao {
  width: 100%;
  background-color: #0e0d30;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  border: 0px;
  font-weight: 500;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 65px;
}
.btn-agendar-reuniao:hover,
.btn-agendar-reuniao:focus,
.btn-agendar-reuniao:link,
.btn-agendar-reuniao:visited,
.btn-agendar-reuniao:active {
  background-color: #201e69;
  color: #fff;
}

.sidebar-avatar {
  width: 120%;
  border-radius: 50%;
}
.sidebar-box hr {
  margin: 15px 0px 15px 0px;
  border-top: 1px solid rgba(0,0,0,0.4);
}
.sidebar-pontuacao {
  padding: 20px 20px;
}
.sidebar-pontuacao a {
  text-decoration: none;
  color: #000;
}
.sidebar-pontuacao a:hover {
  color: #eac115;
}
.sidebar-pontuacao h2 {
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  font-size: 13px;
  color: #022a85;
  text-transform: uppercase;
}
.sidebar-pontuacao h3 {
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  font-size: 18px;
  line-height: 25px;
  color: #000;
}
.sidebar-pontuacao p {
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  font-size: 15px;
  line-height: 13px;
  color: #eac115;
}

.sidebar-agenda {
  padding: 20px 20px;
}
.sidebar-agenda a {
  text-decoration: none;
  color: #000;
}
.sidebar-agenda a:hover {
  color: #eac115;
}
.sidebar-agenda h2 {
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  font-size: 13px;
  color: #022a85;
  text-transform: uppercase;
}
.sidebar-agenda h3 {
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  font-size: 18px;
  line-height: 25px;
  color: #000;
}
.sidebar-agenda p {
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  font-size: 15px;
  line-height: 13px;
  color: #eac115;
}
.agenda-data-box {
  border-radius: 5px;
  padding: 10px 10px 5px 10px;
  background-color: #f3f2ef;
  border: 1px solid rgba(0,0,0,0.4);
}
.agenda-data-position {
  width: 100%;
  font-weight: 700;
  min-height: 80px;
  padding: 40px 0px 43px 0px;
  margin: 0px;
  font-size: 29px;
  line-height: 0px;
  color: #022a85;
  text-align: center;
}
.agenda-data-dia {
  width: 100%;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  line-height: 15px;
  color: #022a85;
  text-align: center;
}
.agenda-data-mes {
  width: 100%;
  text-align: center;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  font-size: 10px;
  color: #000;
  text-transform: uppercase;
}

.content-box .boxes {
  padding: 20px !important;
}
.content-box .boxes .img-destaque img{
  border-radius: 10px;
  width: 100%;
}
.content-box .boxes .avatar {
  width: 100%;
  border-radius: 50%;
}
.avatar {
  width: 100% !important;
  border-radius: 50%;
}
.avatar2 {
  border-radius: 100%;
}
.logo-page {
  width: 100% !important;
}
.content-box .boxes a {
  text-decoration: none;
  color: #000;
}
.content-box .boxes a:hover {
  color: #eac115;
}
.content-box .boxes h3 {
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  font-size: 15px;
  line-height: 20px;
  color: #000;
}
.content-box .boxes h4 {
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  line-height: 12px;
  color: #022a85;
}

.content-box .boxes p {
  font-weight: 300;
  padding: 0px;
  margin: 0px;
  font-size: 15px;
  line-height: 19px;
  color: #000;
  margin-bottom: 14px;
}

.containerSlide {
  display: flex;
}

.itemSlide {
  flex: 0 0 30%;
  text-align: center;
  margin: 0px 10px;
  padding: 10px 0px;
  transition: transform 300ms ease 100ms;
  border-radius: 10px;
}
.itemSlide:first-child {
  margin-left: 25px;
}
.itemSlide img {
  border-radius: 10px;
}
.containerSlide:hover .itemSlide {
  transform: translateX(-5%);
}
.itemSlide:hover ~ .itemSlide {
  transform: translateX(5%);
}
.itemSlide:hover {
  transform: scale(1.1) !important;
}

.carousel-control-prev {
  
}
.carousel-control-next {

}

.perfil-box span {
  font-weight: 300;
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  line-height: 20px;
  color: #000;
  text-transform: uppercase;
}
.perfil-box h2 {
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  line-height: 20px;
  color: #022a85;
}
.perfil-box .interesses hr {
  border-top: 1px solid rgba(0,0,0,0.4);
  padding: 0px;
  margin: 4px 0px;
}
.perfil-box .horarios hr {
  border-top: 1px solid rgba(0,0,0,0.4);
  padding: 0px;
  margin: 8px 0px;
}
.perfil-box .horarios h5 {
  font-weight: 400;
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  color: #022a85;
}
.perfil-box .horarios label {
  font-weight: 300;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.7);
}

.slide-movies a {
  text-decoration: none !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #022a85 !important;
}
.slide-movies a:hover {
  color: #eac115;
}

.box-like {
  background: transparent;
  border: 0px;
  font-weight: 300;
  font-size: 16px;
  color: #022a85;
  padding: 10px 15px;
  border-right: 1px solid rgba(0,0,0,0.1);
}
.box-like i {
  font-weight: 400;
  font-size: 18px !important;
  padding-right: 5px;
}
.box-like:hover {
  background: #022a85;
  border: 0px;
  color: #fff;
  border-right: 1px solid #fff;
}
.box-like:last-child {
  border-right: 0px;
}

.toastr-title {
  color:#000 !important;
}

.toastr-message {
  color:#000 !important;
  background-color: red !important;
}

.btnFilter { 
  height: 57px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
}

.modal {
  top:35px;
  padding: 35px;
}

.TablePagination { 
  padding-top: 8px;
}

.pagine-404 {
  width: 100%;
  text-align: center;
}

.pagine-404 i {
  font-size: 150px;
  padding: 40px 10px 10px 10px;
}
.pagine-404 h2 {
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
  padding: 0px 10px 0px 10px;
}
.pagine-404 h6 {
  font-size: 20px;
  line-height: 40px;
  padding: 0px 10px 100px 10px;
}

.timeline-avatar {
  float: left;
  width: 35px;
}

.timeline-avatar-text {
  padding: 0px 20px;
  float: left;
  min-width: 100px;
}


.toast-title {
  font-weight: bold;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1;
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em;
}



/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  /*overrides*/
}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
#toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center;
}
#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}
#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}
#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}
#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toast {
  background-color: #030303;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px;
  }
}


.overflow-x { 
  overflow-x:auto;
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 95% !important;
  }
}

.ck-balloon-rotator__content {
  z-index: 9999999 !important;
  height: 100vmax;
}
.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all {
  z-index: 9999999 !important;
}
.ck-editor__editable {
  min-height: 500px;
}
.hideItem{
  display: none;
}

.table-responsive {
  overflow-x:inherit;
}
.table-responsive th {
  text-transform: uppercase;
}
.table-responsive th,
.table-responsive td {
  padding: 2px 5px !important;
}